<template>
  <div>
    <a-row class="left">
        <a-col class="title">91再生交易平台上线</a-col>
        <a-col class="date"><a-icon type="clock-circle" /> 2021-12-15</a-col>
        <a-divider class="main"></a-divider>
        <div class="content">
            <p>针对再生资源行业合规乱象问题，91再生通过多年的交易经验并与相关部门合作，提供再生资源合规解决方案，通过“互联网+”的方式，将交易信息化，方便监管溯源，同时通过云端开票等方式实现订单与发票全流程一体化操作。</p>
            <br/>
            <p>行业现状：</p>
            <ul>
                <li>缺乏进项发票，增值税难以实现抵扣，大幅提高了回收企业的成本。</li>
                <li>再生资源加工环节的税收优惠难以传导到回收环节的正规企业。</li>
                <li>现行税收政策导致再生资源价格倒挂，损害其相对于原生资源的价格竞争力。</li>
                <li>各地税收政策差异较大，市场竞争环境不公平。</li>
            </ul>
            <br/>
            <p>91再生交易平台通过信息化、区块链、轨迹跟踪、电子签约、智能地磅、物联网等方式，主动采集交易证据，通过多角度验证再生资源交易真实性，通过与工商、税务部门的数据共享，实现交易实时监管与溯源，在证据完善的情况下为卖方提供记账服务。</p>
            <br/><br/>
            <p><b>平台特点：发票合规性，支持纸制及电子发票，拾荒者无成本增加，拾荒者交易便捷性，确保交易环节相关税费，成熟 的税务综合服务，支付7*24小时T+0转账</b></p>
        </div>

        <a-row :gutter="20">
            <a-col :span="12">
                <div class="next"  @click="$router.push('/notice-details/s5')">
                    <div class="align">
                        <p class="to">上一篇</p>
                        <p class="to-title">91再生网依法进行主体登记，履行纳税义务</p>
                    </div>
                </div>
            </a-col>
            <a-col :span="12">
                <div class="next"  @click="$router.push('/notice-details/s2')">
                    <div class="align">
                        <p class="to">下一篇</p>
                        <p class="to-title">小规模增值税1%征收率将于2021年12月31结束</p>
                    </div>
                </div>
            </a-col>
        </a-row>
    </a-row>

    <a-row class="right">
        <a-col class="date">其他公告</a-col>
        <a-divider class="main"></a-divider>
        
        <a-row class="r-item" v-for="item in list" :key="item.title">
            <a-col class="to-title" @click="$router.push(item.router)">{{item.title}}</a-col>
            <a-col class="r-text">{{item.text}}</a-col>
            <a-col class="r-date"><a-icon type="clock-circle" /> 2021-12-15</a-col>
            <a-divider></a-divider>
        </a-row>
    </a-row>
  </div>
</template>

<script>
export default {
    data() {
        return {
            list: [
                {title:'增值税1%征收率即将结束',text:'小规模增值税1%征收率将于2021年12月31结束，2022年1月1日起按3%征收...',router: '/notice-details/s2'},
                {title:'91再生交易平台执行“实名认证”入驻',text:'为确保交易自然人的知悉权，避免身份被他人借用的情况，平台卖家入驻时需...',router: '/notice-details/s3'},
                {title:'关于个体工商户发票存证限额的通知',text:'根据《国家税务总局关于统一小规模纳税人标准等若干增值税问题的公告》...',router: '/notice-details/s4'},
                {title:'91再生网依法进行主体登记，履行纳税义务',text:'依据《中华人民共和国电子商务法》, 91再生交易平台依法进行主体登记，履行纳税义务...',router: '/notice-details/s5'},
            ]
        }
    }
}
</script>

<style lang="less" scoped>
.left {
    @media screen and (max-width: 1440px){
        width: 60%;
    }
    display: inline-block;
    vertical-align: top;
    width: 960px;
    margin-right: 42px;
}
.right {
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 1440px){
        width: calc(40% - 42px);
    }
    width: 352px;
    line-height: 24px;
}
.title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 16px;
}
.date {
    color: #AAAAAA;
    font-size: 16px;
}
.content {
    p{
        line-height: 26px;
        text-indent: 2em;
    }
    li {
        margin-left: 3.5em;
        line-height: 26px;
        list-style: initial;
    }
}
.to-title {
    cursor: pointer;
    font-weight: 700;
    color: #666666;
    font-size: 18px;
    transition: all .1s;
    &:hover {
        color: rgba(234, 164, 0, 1);
    }
}
.next {
    margin: 62px 0;
    cursor: pointer;
    border-left: 3px solid rgba(215, 215, 215, 1);
    background-color: rgba(242, 242, 242, 1);
    height: 100px;
    position: relative;
    transition: all .1s;
    .align {
        margin-left: 20px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        .to {
            color: rgb(170, 170, 170);
            line-height: 28px;
        }
    }
    &:hover {
        background-color: rgba(68, 165, 40, 1);
        .to,.to-title {
            color: #fff !important;
        }
    }
}
.r-item {
    .r-date {
        color: #AAAAAA;
        font-size: 14px;
    }
    .r-text {
        font-size: 14px;
        color: #AAAAAA;
        margin-top: 6px;
        line-height: 24px;
    }
}
/deep/ .ant-divider-horizontal{
    margin: 12px 0;
}
.main {
    background: #d7d7d7;
    margin: 12px 0 16px 0;
}
</style>